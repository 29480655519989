import React from "react";
import styles from "./PriorityDashboard.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenDropdownCheck from "../../components/GenDropdownCheck";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITIES, Date12, calculateAgeInYears,PRIORITY_HOLDING} from "../../AppConstants";

const PriorityDashboard = (props) => {
    const [matrix,setMatrix] = React.useState({});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [tickets,setTickets] = React.useState([]);
    const [showtickets,setShowTickets] = React.useState(false);
    const [ticket,setTicket] = React.useState(null);
    const [showticket,setShowTicket] = React.useState(false);
    const [selectedtids,setSelectedTids] = React.useState('');
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');
    const [holding, setHolding] = React.useState(0);
    const [agentsortfield,setAgentSortField] = React.useState('');
    const [agentsortorder,setAgentSortOrder] = React.useState('');

    const initDashboard = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const obj = {practices:[],
            priorities:[],
            msgtypes:[]
        }
        console.log('PDB Init called')
        dataservice.initprioritydashboard(props.globals.useraccountid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.MATRIX);
            setTickets([]);

            console.log('DB Init matrix ready')
        })
    }

    React.useEffect(()=> {
        initDashboard();
    },[refreshneeded])

    const handleRefresh = (e) => {
        initDashboard();
    }


    function decimalToHoursMinutes(num) {
        const total = Math.floor(num);
        const hours = Math.floor(total / 3600); // Calculate hours
        const minutes = Math.floor((total % 3600) / 60); // Calculate minutes
        const seconds = total % 60; // Calculate remaining seconds
      
        // Pad single-digit values with leading zeros
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; // Return formatted string
      }

    const findAgent = (uaid) => {
        const agents = matrix.AGENTS || [];
        let agent = null;
        agents.every((ag)=> {
            if (''+ag.uaid===''+uaid) {
                agent = ag;
                return false;
            }
            return true;
        })
        return agent;
    }

    const readTickets = (ticklist=null) => {
        if (!ticklist) ticklist = selectedtids;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticklist(props.globals.useraccountid,ticklist).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            let tlist = JSON.parse(JSON.stringify(data.TICKETS || []));
            const now = new Date();
            tlist.forEach((t)=> {
                const dt = new Date(t.eventdate || null);
                const tm = Math.abs(now.getTime() - dt.getTime());
                const m = Math.floor(tm / (1000 * 60));
                t.age = m;
            })
            setTickets(tlist);
            setShowTickets(true);
        })
    }

    const handleSelect = (e) => {
        const tid = e.target.id;
        const sa = tid.split("_");
        console.log("HS: "+sa);
        setShowTickets(false);
        if (sa.length > 1) {
            const cmd = sa[0];
            const prid = sa[1];
            let tidparm = '';
            let tidlist = [];
            if (cmd === 'nt') {
                let tc = matrix.ticketcounts || {};
                tidlist = tc[prid] || [];
                tidparm = tidlist.join(',');
            }
            if (cmd === 'lng') {
                let tl = matrix.ticketlongest || {};
                tidparm = tl[prid] || '';
                tidparm = '' + tidparm;
            }
            if (tidparm.length) {
                readTickets(tidparm);
            }
        }
    }

    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTicket(data.TICKET);
            setShowTicket(true);
        })
    };

    const ShowSelectedTickets = () => {
        if (tickets.length === 0) {
            return (
                <React.Fragment>
                    <div style={{marginTop:"30px",marginLeft:"18px"}}>Click on ticket numbers to display ticket information</div>
                </React.Fragment>
            )
        }

        if (ticketsortfield.length > 0) {
            tickets.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }
        const formatMinutesToHoursMinutes = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes}`;
          }

        return (
            <React.Fragment>
                <div style={{marginTop:"20px",marginLeft:"30px"}}>Priorities with Yellow backgrounds indicates caller is On-Hold</div>
                <div className={styles.listrowhdr} style={{marginTop:"10px"}}>
                    <div id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="priority" onClick={handleSortClick}>Priority{ticketsortfield==='priority' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='priority' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="eventname" onClick={handleSortClick}>State{ticketsortfield==='eventname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='eventname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="age" onClick={handleSortClick}>Time in State{ticketsortfield==='age' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='age' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                <div style={{height:"10px"}}></div>
                {tickets.map((entry,i) => {
                    const ptage = calculateAgeInYears(entry.dob);
                    let phones = "";
                    let bg = null;
                    if (entry.priority===PRIORITY_HOLDING && holding===0) {
                        bg = "yellow";
                    }
                    let rowbg = "white";
                    if (entry===selectedEntry) {
                        rowbg = "lightgreen";
                    }
                    if (entry.callbackphone.length > 0) {
                        phones = formatPhoneNumber(entry.callbackphone);
                    }
                    if (entry.secondaryphone.length > 0) {
                        if (phones.length > 0) phones += " , "
                        phones += formatPhoneNumber(entry.secondaryphone);
                    }
                    return (
                        <div key={"phone_"+i} className={styles.listrow} onClick={()=>handleEntryClick(entry)}>
                            <div>{entry.ticketid}</div>
                            <div>{entry.msgname}</div>
                            <div style={{backgroundColor:bg}}>{PRIORITIES[entry.priority]}</div>
                            <div>{Date12(entry.createdate)}</div>
                            <div>{entry.eventname}</div>
                            <div>{formatMinutesToHoursMinutes(entry.age)}</div>
                            <div className={styles.gridcell}>{entry.firstname}</div>
                            <div className={styles.gridcell}>{entry.lastname}</div>
                            <div>{DateOnly(entry.dob)} ({ptage})</div>
                            <div>{phones}</div>
                            <div className={styles.gridcell}>{entry.pracname}</div>
                        </div>
                    )
                    
                })}
            </React.Fragment>
        )
    }

    const ShowPriorities = () => {
        let counts = matrix.queuecounts || {};
        let avgs = matrix.averages || {};
        let longest = matrix.queuelongest || {};

        return (
            <div className={styles.matrixblock}>
                <div className={styles.matrixhdr} style={{userSelect:"none"}}>
                    <div id="priority">Priority</div>
                    <div id="numtickets">Num Tickets</div>
                    <div id="average">Average Time</div>
                    <div id="longest">Longest Time</div>
                </div>
                {PRIORITIES.map((pr,i)=> {
                    if (pr.includes("+")) return null;
                    let count = counts[i] || 0;
                    let avg = avgs[i] || 0;
                    let longtime = longest[i] || 0;
                    return (
                        <div key={"prrow_"+i} className={styles.matrixrow}>
                            <div>{pr}</div>
                            <div id={"nt_"+i} className={styles.matrixblockcell} style={{textAlign:"left",cursor:"pointer"}} onClick={handleSelect}>{count}</div>
                            <div className={styles.matrixblockcell}>{decimalToHoursMinutes(avg)}</div>
                            <div id={"lng_"+i} className={styles.matrixblockcell} style={{paddingLeft:"10px",cursor:"pointer"}} onClick={handleSelect}>{decimalToHoursMinutes(longtime)}</div>

                        </div>
                    )
                })}
            </div>
        )
    }
    
    const ticketOnClose = (ticketid,stateid,priority) => {
        setShowTicket(false);
    }

    return (
        <React.Fragment>
                <div>
                <div className={styles.title}>Priority Dashboard
                <span style={{marginLeft:"50px"}}><button style={{height:"26px",fontSize:"16px"}} onClick={handleRefresh}>Refresh</button></span>
                </div>
                {ShowPriorities()}
                {showtickets && ShowSelectedTickets()}
                {showticket && <TicketDetail globals={props.globals} methods={props.methods} allowpriorityedit={false} disableworkflow={true} ticket={ticket} doClose={ticketOnClose} />}                
            </div>
        </React.Fragment>
    )
}

export default PriorityDashboard;