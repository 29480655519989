
import axios from 'axios';
import {BASE_URL} from './../AppConstants';

export class DataService {
    constructor(props) {
        this.guid = props.guid;
      }       

    setGuid = (guid) => this.guid=guid;

    async checklogin(uname,upwd) {
        //const url = BASE_URL + 'ptslin?uname='+uname+'&pwd='+upwd;
        const uobj = {uname:uname,pwd:upwd};

        const url = BASE_URL + 'ptslin';
        const res = await axios.post(url,JSON.stringify(uobj));
        return res.data;
    }

    async dologout(uaid) {
        const url = BASE_URL + 'ptslout?gid='+this.guid;
        const res = await axios.post(url+`&uaid=${uaid}`);
        return res.data;
    }

    async getalllocalpatients() {

        const url = BASE_URL + 'rap?gid='+this.guid
        console.log('GetAllLocalPatients, url='+url);
        const res = await axios.get(url);
        return res.data;
    }

    async getuserfunctions(framework) {
        const res = await axios.get(BASE_URL + 'readuf?fw='+framework+'&gid='+this.guid);
        return res.data;
    }

    async getallusers(userId,practiceId) {
        const url = BASE_URL + 'readusers?userid=' + userId+'&pracid='+practiceId+'&gid='+this.guid
        console.log('GetAllUsers, url='+url);
        const res = await axios.get(url);
        return res.data;
    }
    
    async updatematrix(userId,matrix) {
        const res = await axios.post(BASE_URL + 'updmatrix?userid='+userId+'&matrix='+JSON.stringify(matrix)+'&gid='+this.guid);
        return res.data;
    }

    async verifypasswordrequest(guid,email) {
        const res = await axios.post(BASE_URL + 'vpr?gid='+guid+'&email='+email);
        return res.data;
    }

    async updateuserproperties(userId,propname,propvalue) {
        const res = await axios.post(BASE_URL + `updusrprop?userid=${userId}&${propname}=${propvalue}&gid=${this.guid}`);
        return res.data;
    }

    async getallpractices() {
        const res = await axios.get(BASE_URL + 'readallprac?gid='+this.guid);
        return res.data;
    }

    async getdecisiontree(pracid=0,decisionid=0) {
        const res = await axios.get(BASE_URL + "readdectree?gid="+this.guid+ `&pracid=${pracid}&decid=${decisionid}`);
        return res.data;
    }

    async getpracticemessages(pracid,sortid,statusid,msgtype,respid=0,resptype='A',userid=0,msgid=0,readall=false,fromdt="",todt="",arch='N') {
        const readit = readall===false ? 0 : 1;
        const res = await axios.get(BASE_URL + 'pmh?gid='+this.guid + 
        `&pid=${pracid}&sid=${sortid}&stid=${statusid}&mtid=${msgtype}&respid=${respid}&resptype=${resptype}`+
        `&userid=${userid}&msgid=${msgid}&readall=${readit}&fromdt=${fromdt}&todt=${todt}&arch=${arch}`);
        return res.data;
    }

    async getreviewmessages(pracid,sortid,statusid,msgtype,respid=0,resptype='A',userid=0,msgid=0,readall=false,fromdt="",todt="",arch='N') {
        const readit = readall===false ? 0 : 1;
        const res = await axios.get(BASE_URL + 'rmh?gid='+this.guid + 
        `&pid=${pracid}&sid=${sortid}&stid=${statusid}&mtid=${msgtype}&respid=${respid}&resptype=${resptype}`+
        `&userid=${userid}&msgid=${msgid}&readall=${readit}&fromdt=${fromdt}&todt=${todt}&arch=${arch}`);
        return res.data;
    }

    async getmessagedetail(pracid,msgid) {
        const res = await axios.get(BASE_URL + 'gmd?gid='+this.guid + `&pid=${pracid}&mid=${msgid}`);
        return res.data;
    }
    /*
    async savenote(MessageID, MessageDate, ResponsibleID, ResponsibleType, UserAccountID, ActionID, Comment, Reason, ContactMethod , ContactPersonID, ContactGroupID, AuditUserAccountId, AuditPracticeId, RecordName) {
        const res = await axios.post(BASE_URL+'savenote?gid='+this.guid+`&msgid=${MessageID}&msgdt=${MessageDate}&respid=${ResponsibleID}&resptype=${ResponsibleType}&uacid=${UserAccountID}&actionid=${ActionID}&cmt=${Comment}&reason=${Reason}&ctmeth=${ContactMethod}&ctpersonid=${ContactPersonID}&ctgroupid=${ContactGroupID}&auacid=${AuditUserAccountId}&apracid=${AuditPracticeId}&recname=${RecordName}`);
        return res.data;
    }
    */
    async savenote(obj) {
        const datajs = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savenote?gid='+this.guid,datajs);
        return res.data;
    }

    async savemessage(data) {
        const datajs = JSON.stringify(data);
        const res = await axios.post(BASE_URL+'savemsg?gid='+this.guid,datajs);
        return res.data;
    }

    async closemessages(uacid,pracid,msglist,reopen=0) {
        //const res = await axios.post(BASE_URL+'clsemsg?gid='+this.guid+`&uacid=${uacid}&pracid=${pracid}&msgs=${msglist}`);
        const res = await axios.post(BASE_URL+'clsemsg?gid='+this.guid+`&uacid=${uacid}&pracid=${pracid}&reopen=${reopen}`,JSON.stringify(msglist));
        return res.data;
    }

    async getoncall(pracid,sdate="",edate="",cdate="",oncallid) {
        const res = await axios.get(BASE_URL+'goc?gid='+this.guid+`&pracid=${pracid}&sdate=${sdate}&edate=${edate}&curdate=${cdate}&oncallid=${oncallid}`);
        return res.data;
    }

    async getcurrentoncall(oncallid,bkuseq=1,curdate=null) {
        const options={month:"numeric",day:"numeric",year:"numeric"};
        let dt;
        if (curdate===null) {
            dt = new Date();
        } else {
            dt = curdate;
        }
        dt = dt.toLocaleDateString(undefined,options);
        const res = await axios.get(BASE_URL+'getcuroncall?gid='+this.guid+`&oncallid=${oncallid}&bkuseq=${bkuseq}&caldate=${dt}`);
        return res.data;
    }

    async saveoncall(pracid,oncallid,cdate,uacid,data) {
        const datajs = JSON.stringify(data);
        const options={month:"numeric",day:"numeric",year:"numeric"}
        const curdate = cdate.toLocaleDateString(undefined,options);
        const res = await axios.post(BASE_URL+'soc?gid='+this.guid+`&pracid=${pracid}&oncallid=${oncallid}&uacid=${uacid}&caldate=${curdate}`,datajs);
        return res.data;
    }

    async saveoncallcalendar(pracid,oncallid,uacid,data) {
        let datajs = JSON.stringify(data);
        const res = await axios.post(BASE_URL+'savecal?gid'+this.guid+`&pracid=${pracid}&ocid=${oncallid}&uacid=${uacid}`,datajs);
        return res.data;
    }

    async savecontactmethods(personid,uacid,pracid,data) {
        const datajs = JSON.stringify(data);
        const res = await axios.post(BASE_URL+'scm?gid='+this.guid+`&personid=${personid}&uacid=${uacid}&pracid=${pracid}`,datajs);
        return res.data;
    }

    async getproviders(pracid) {
        const res = await axios.get(BASE_URL+'getprov?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async updateprovider(pracid,provobj) {
        //const res = await axios.post(BASE_URL+'updprov?gid='+this.guid+'&pracid='+pracid+'&po='+encodeURIComponent(JSON.stringify(provobj)));
        const res = await axios.post(BASE_URL+'updprov?gid='+this.guid+'&pracid='+pracid,JSON.stringify(provobj));
        return res.data;
    }

    async getusers(pracid) {
        const res = await axios.get(BASE_URL+'getuser?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;

    }

    async updateuser(pracid,userobj) {
        //const res = await axios.post(BASE_URL+'upduser?gid='+this.guid+'&pracid='+pracid+'&po='+encodeURIComponent(JSON.stringify(userobj)));
        const res = await axios.post(BASE_URL+'upduser?gid='+this.guid+'&pracid='+pracid,JSON.stringify(userobj));
        return res.data;
    }
    
    async updatepwd(pracid,uacid,oldpw,newpw) {
        const res = await axios.post(BASE_URL+'uppw?gid='+this.guid+`&pracid=${pracid}&uid=${uacid}&opw=${oldpw}&npw=${newpw}`);
        return res.data;
    }

    async getrecipients(pracid) {
        const res = await axios.get(BASE_URL+'getrecip?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async getmessageheaderint(pracid) {
        const res = await axios.get(BASE_URL+'getmhint?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async deletesysmsg(sysmsguserid,uacid,pracid) {
        const res = await axios.post(BASE_URL+'delsysmsg?gid='+this.guid+`&smuid=${sysmsguserid}&uaid=${uacid}&pracid=${pracid}`);
        return res.data;
    }

    async getactivepractices(pracid=0,detail=0) {
        const res = await axios.get(BASE_URL+'gap?gid='+this.guid+`&pid=${pracid}&detail=${detail}`);
        return res.data;
    }

    async validatesession() {
        console.log("Validate Session called");
        const res = await axios.get(BASE_URL+'valses?gid='+this.guid);
        return res.data;
    }

    async takemessageinit(pracid) {
        const res = await axios.get(BASE_URL+'tmi?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async msgactioninit(pracid,msgid) {
        const res = await axios.get(BASE_URL+'gmd?gid='+this.guid+`&pid=${pracid}&mid=${msgid}`);
        return res.data;
    }


    async urgentmessageinit(pracid,msgid=0) {
        const res = await axios.get(BASE_URL+'umi?gid='+this.guid+`&pracid=${pracid}&msgid=${msgid}`);
        return res.data;
    }
    
    async geturgentmessages() {
        const res = await axios.get(BASE_URL+'geturgent?gid='+this.guid);
        return res.data;
    }

    async pageprovider(pageobj) {
        const jsobj = JSON.stringify(pageobj);
        const res = await axios.post(BASE_URL+'pageprov?gid='+this.guid,jsobj);
        return res.data;
    }

    async contactaction(contactobj) {
        const jsobj = JSON.stringify(contactobj);
        const res = await axios.post(BASE_URL+'conact?gid='+this.guid,jsobj);
        return res.data;
    }

    async batchack(batchobj) {
        const jsobj = JSON.stringify(batchobj);
        const res = await axios.post(BASE_URL+'batchack?gid='+this.guid,jsobj);
        return res.data;
    }

    async getlocations(pracid) {
        const res = await axios.get(BASE_URL+'getloc?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savelocation(pracid,locid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'saveloc?gid='+this.guid+`&pracid=${pracid}&locid=${locid}&uacid=${uacid}`,jsobj);
        return res.data;
    }

    async getpracticeproviders(pracid,sdate="",edate="") {
        const res = await axios.get(BASE_URL+'getpracprov?gid='+this.guid+`&pracid=${pracid}&sdate=${sdate}&edate=${edate}`);
        return res.data
    }

    async getcallnotes(pracid) {
        const res = await axios.get(BASE_URL+'getcallnotes?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async managepracticeinit(pracid=0) {
        const res = await axios.get(BASE_URL + 'pracmgrinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savepractice(pracid,locid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'saveprac?gid='+this.guid+`&pracid=${pracid}&locid=${locid}&uacid=${uacid}`,jsobj);
        return res.data;
    }

    async getpracticespecialties(pracid) {
        const res = await axios.get(BASE_URL+'getpracspec?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savepracticespecialties(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savepracspec?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async oncallprotocolinit(pracid) {
        const res = await axios.get(BASE_URL+'ocpinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async oncallprotocolsave(pracid,providerid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'ocpsave?gid='+this.guid+`&pracid=${pracid}&provid=${providerid}`,jsobj);
        return res.data;
    }

    async oncallinit(pracid) {
        const res = await axios.get(BASE_URL+'oncallinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async addnewgroup(pracid,uaid,uapracid,desc) {
        const res = await axios.post(BASE_URL+'addnewgroup?gid='+this.guid+`&pracid=${pracid}&uaid=${uaid}&uapracid=${uapracid}`,desc);
        return res.data;        
    }

    async savegroups(pracid,uaid,uapracid,data) {
        const jsobj = JSON.stringify(data);
        const res = await axios.post(BASE_URL+"savegroups?gid="+this.guid+`&pracid=${pracid}&uaid=${uaid}&uapracid=${uapracid}`,jsobj);
        return res.data;
    }

    async callnoteinit(pracid=0) {
        const res = await axios.get(BASE_URL + 'callnoteinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savecallnotes(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savecallnotes?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async deletecallnote(pracid,uacid,pcnid) {
        const res = await axios.get(BASE_URL+'delcallnote?gid='+this.guid+`&pracid=${pracid}&pcnid=${pcnid}&uacid=${uacid}`);
        return res.data;        
    }

    async getsystemlists() {
        const res = await axios.get(BASE_URL+'getsyslists?gid='+this.guid);
        return res.data;
    }

    async savecreds(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savecreds?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async savespecs(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savespecs?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async saveinfotypes(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'saveinfotypes?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async savemsgtypes(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savemsgtypes?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async savesys(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savesys?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }
    
    async savesysmsg(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savesysmsg?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async getroles() {
        const res = await axios.get(BASE_URL+'getroles?gid='+this.guid);
        return res.data;
    }

    async msgcontentinit(pracid) {
        const res = await axios.get(BASE_URL + 'msgcontentinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savemsgcontent(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savemsgcontent?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async getqualquestions(pracid) {
        const res = await axios.get(BASE_URL + 'getqq?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async savequalquestions(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'saveqq?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async getsystemconfig() {
        const res = await axios.get(BASE_URL + 'getsysconfig?gid='+this.guid);
        return res.data;
    }

    async savesystemconfig(uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savesysconfig?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async checkalerts(uacid,readalert=0,rdmsgid=0) {
        const res = await axios.get(BASE_URL+'checkalerts?gid='+this.guid+`&uacid=${uacid}&readalert=${readalert}&rdmsgid=${rdmsgid}`)
        return res.data;
    }

    async readalert(msgid) {
        const res = await axios.get(BASE_URL + 'readalert?gid='+this.guid+`&msgid=${msgid}`);
        return res.data;
    }

    async alertowned(uacid,qaid) {
        const res = await axios.get(BASE_URL+'alertowned?gid='+this.guid+`&uacid=${uacid}&qaid=${qaid}`)
        return res.data;
    }


    async returntoqueue(uacid,qdata) {
        const jsobj = JSON.stringify(qdata);
        const res = await axios.post(BASE_URL+'retqueue?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;
    }

    async getsystemstatus(dtfrom="",dtto="") {
        const res = await axios.get(BASE_URL + 'getsysstatus?gid='+this.guid+`&dtfrom=${dtfrom}&dtto=${dtto}`);
        return res.data;
    }

    async clearalerts(uacid,qdata) {
        const jsobj = JSON.stringify(qdata);
        const res = await axios.post(BASE_URL+'clearalerts?gid='+this.guid+`&uacid=${uacid}`,jsobj);
        return res.data;
    }

    async resetalert(uacid,qaid) {
        const res = await axios.get(BASE_URL+'resetalert?gid='+this.guid+`&uacid=${uacid}&qaid=${qaid}`);
        return res.data;
    }

    async searcharchive(pracid,params) {
        const jsobj = JSON.stringify(params);
        const res = await axios.post(BASE_URL+'searcharch?gid='+this.guid+`&pracid=${pracid}`,jsobj);
        return res.data;
    }

    async getarchivemessage(pracid,msgarcid) {
        const res = await axios.get(BASE_URL+'getarchmsg?gid='+this.guid+`&pid=${pracid}&mid=${msgarcid}`);
        return res.data;
    }

    async createlocation(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'createloc?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async savesecure(ppid,groupname,groupnum) {
        const res = await axios.post(BASE_URL+'savesecure?gid='+this.guid+`&ppid=${ppid}&gname=${groupname}&gnum=${groupnum}`);
        return res.data;
    }

    async resetsecure(personid,cellnumber) {
        const res = await axios.post(BASE_URL+'resetsecure?gid='+this.guid+`&personid=${personid}&cell=${cellnumber}`);
        return res.data;
    }

    async lookupsecure(ppid) {
        const res = await axios.get(BASE_URL+'lookupsecure?gid='+this.guid+`&ppid=${ppid}`);
        return res.data;
    }

    async savepracticemsgtypes(pracid,uacid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savepmt?gid='+this.guid+`&pracid=${pracid}&uacid=${uacid}`,jsobj);
        return res.data;        
    }

    async getscheduledmessages(pracid,sortid,statusid,msgtype,respid=0,resptype='A',userid=0,msgid=0,readall=false,fromdt="",todt="",arch='N') {
        const readit = readall===false ? 0 : 1;
        const res = await axios.get(BASE_URL + 'getsm?gid='+this.guid + 
        `&pid=${pracid}&sid=${sortid}&stid=${statusid}&mtid=${msgtype}&respid=${respid}&resptype=${resptype}`+
        `&userid=${userid}&msgid=${msgid}&readall=${readit}&fromdt=${fromdt}&todt=${todt}&arch=${arch}`);
        return res.data;
    }
    
    async getscheduledetail(pracid,msgid) {
        const res = await axios.get(BASE_URL + 'getsd?gid='+this.guid + `&pid=${pracid}&mid=${msgid}`);
        return res.data;
    }
    
    async closeschedmessages(uacid,pracid,msglist,reopen=0) {
        //const res = await axios.post(BASE_URL+'clsesched?gid='+this.guid+`&uacid=${uacid}&pracid=${pracid}&msgs=${msglist}`);
        const res = await axios.post(BASE_URL+'clsesched?gid='+this.guid+`&uacid=${uacid}&pracid=${pracid}&reopen=${reopen}`,JSON.stringify(msglist));
        return res.data;
    }

    async getwork(pracid,uaid,practiceid=0,ticketid=0,priority=999) {
        const res = await axios.get(BASE_URL + 'getwork?gid='+this.guid + `&pid=${pracid}&uaid=${uaid}&pid=${practiceid}&tckid=${ticketid}&priority=${priority}`);
        return res.data;
    }

    async readschedmsg(msgid) {
        const res = await axios.get(BASE_URL + 'readsd?gid='+this.guid + `&msgid=${msgid}`);
        return res.data;
    }

    async readbytype(msgid,msgtype,setinuse) {
        const res = await axios.get(BASE_URL + 'readbytype?gid='+this.guid + `&msgid=${msgid}&msgtype=${msgtype}&inuse=${setinuse}`);
        return res.data;
    }

    async readworkflowbytype(msgtype,ticketid=0) {
        const res = await axios.get(BASE_URL + 'readwfbt?gid='+this.guid + `&msgtype=${msgtype}&ticketid=${ticketid}`);
        return res.data;
    }
    async setticketevent(ticketid,tostateid,priority,uaid) {
        const res = await axios.post(BASE_URL+'setticketevent?gid='+this.guid+`&ticketid=${ticketid}&tostateid=${tostateid}&priority=${priority}&uaid=${uaid}`);
        return res.data;
    }
    
    async gototicketstate(ticketid,tostateid,priority,uaid,holding=0) {
        const res = await axios.post(BASE_URL+'gototckst?gid='+this.guid+`&ticketid=${ticketid}&tostateid=${tostateid}&priority=${priority}&uaid=${uaid}&holding=${holding}`);
        return res.data;
    }

    async addticketnote(noteobj) {
        const res = await axios.post(BASE_URL+'addticketnote?gid='+this.guid,JSON.stringify(noteobj));
        return res.data;
    }

    async searchtickets(holding,fname,lname,dob,phone,tknum=0) {
        const res = await axios.get(BASE_URL + 'searchtickets?gid='+this.guid + `&holding=${holding}&fname=${fname}&lname=${lname}&dob=${dob}&phone=${phone}&tknum=${tknum}`);
        return res.data;
    }

    async readescalated(uaid) {
        const res = await axios.get(BASE_URL + 'readesc?gid='+this.guid + `&uaid=${uaid}`);
        return res.data;
    }

    async readholding(uaid) {
        const res = await axios.get(BASE_URL + 'readholding?gid='+this.guid + `&uaid=${uaid}`);
        return res.data;
    }

    async readticketdetail(uaid,ticketid) {
        const res = await axios.get(BASE_URL + 'readtickdet?gid='+this.guid + `&uaid=${uaid}&ticketid=${ticketid}`);
        return res.data;
    }

    async setticketpriority(ticketid,priority,uaid) {
        const res = await axios.post(BASE_URL+'setticketpriority?gid='+this.guid+`&ticketid=${ticketid}&priority=${priority}&uaid=${uaid}`);
        return res.data;
    }

    async settickethold(ticketid,holding,uaid) {
        const res = await axios.post(BASE_URL+'setticketholding?gid='+this.guid+`&ticketid=${ticketid}&holding=${holding}&uaid=${uaid}`);
        return res.data;
    }

    async tickettoqueue(ticketid,uaid,comment) {
        const obj = {ticketid:ticketid,uaid:uaid,comment:comment};
        const res = await axios.post(BASE_URL+'tickettoqueue?gid='+this.guid,JSON.stringify(obj));
        return res.data;
    }

    async workflowsinit(holding,fname,lname,dob,phone) {
        const res = await axios.get(BASE_URL + 'wfi?gid='+this.guid);
        return res.data;
    }

    async saveworkflow(uaid,wfobj) {
        const res = await axios.post(BASE_URL+'savewf?gid='+this.guid+`&uaid=${uaid}`,JSON.stringify(wfobj));
        return res.data;
    }

    async saveallworkflows(uaid,wflist) {
        const res = await axios.post(BASE_URL+'saveallwf?gid='+this.guid+`&uaid=${uaid}`,JSON.stringify(wflist));
        return res.data;
    }

    async transfernoteinit(pracid=0) {
        const res = await axios.get(BASE_URL + 'transfernoteinit?gid='+this.guid+`&pracid=${pracid}`);
        return res.data;
    }

    async deletetransfernote(pracid,uacid,ptnid) {
        const res = await axios.get(BASE_URL+'deltransfernote?gid='+this.guid+`&pracid=${pracid}&ptnid=${ptnid}&uacid=${uacid}`);
        return res.data;        
    }

    async savetransfernote(pracid,uaid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savetransfernote?gid='+this.guid+`&pracid=${pracid}&uaid=${uaid}`,jsobj);
        return res.data;        
    }

    async updatetransfersequence(pracid,uaid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'updtransferseq?gid='+this.guid+`&pracid=${pracid}&uacid=${uaid}`,jsobj);
        return res.data;        
    }

    async initdashboard(uaid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'initdashbd?gid='+this.guid+`&uaid=${uaid}`,jsobj);
        return res.data; 
    }

    async readticklist(uaid,tids) {
        const jsobj = JSON.stringify({tids:tids});
        const res = await axios.post(BASE_URL+'readticklist?gid='+this.guid+`&uaid=${uaid}`,jsobj);
        return res.data;        
    }

    async savethresholds(uaid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'savethresholds?gid='+this.guid+`&uaid=${uaid}`,jsobj);
        return res.data;        
    }

    async initagentdashboard(uaid,obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'initagentdb?gid='+this.guid+`&uaid=${uaid}`,jsobj);
        return res.data; 
    }

    async initclosedtickets() {
        const res = await axios.get(BASE_URL+'initclosedtk?gid='+this.guid);
        return res.data;        
    }

    async searchclosedtickets(obj) {
        const jsobj = JSON.stringify(obj);
        const res = await axios.post(BASE_URL+'searchclosed?gid='+this.guid,jsobj);
        return res.data;
    }

    async inituserdashboard(uaid) {
        const res = await axios.get(BASE_URL+'inituserdb?gid='+this.guid+`&uaid=${uaid}`);
        return res.data; 
    }

    async initclientdashboard() {
        const res = await axios.get(BASE_URL+'initclientdb?gid='+this.guid);
        return res.data; 
    }

    async initprioritydashboard() {
        const res = await axios.get(BASE_URL+'initprioritydb?gid='+this.guid);
        return res.data; 
    }

}
